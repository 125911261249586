import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: `Good to see you!`,
  paraTwo: `Certified Architect Professional & Engineer in AWS and GCP with web apps pedigree.`,
};

const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Mircea', 'Adam', 'DevOps', 'Engineer']}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>
          {pageText.paraOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="launch.png"
            alt="launch projects image"
            height="90"
            width="90"            
            textH4="Project Work"
            textH3="My favorite kind"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="professor.png"
            alt="Presentations"
            height="90"
            width="90"
            textH4="Tech Presentations"
            textH3="AClabs, CM, internal"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="ac-cti.svg"
            alt="Automatica si Calculatoare"
            textH4="AC CTI"
            textH3="Engineer Degree"
            height={100}
            width={150}
          />
        </Col>        
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="aws-architect.png"
            alt="AWS Certified Proffesional"
            height="90"
            width="80"
            textH4="Amazon Web Services"
            textH3="Solutions Architect Proffesional"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="gcp-architect.png"
            alt="gcp-architect image"
            height="95"
            width="95"            
            textH4="Google Cloud Platform"
            textH3="Cloud Architect Proffesional"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="reason11.jpg"
            alt="Reason Studios"
            textH4="Reason Studios"
            textH3="Yes, I make music sometimes.."
            height={80}
            width={80}
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
