import React from 'react';
import { Row, Col } from 'antd';
import ProgressBar from '../../Progress';

const SkillsProgress = () => (
  <div>
    <br></br>
    <h2>Skills</h2>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12}>

        <ProgressBar
          percent={90}
          text="AWS (Architect Certified Professional)"
        />
        <ProgressBar
          percent={40}
          text="GCP"
        />
        <ProgressBar
          percent={70}
          text="Docker"
        />
        <ProgressBar
          percent={75}
          text="Serverless"
        />
      </Col>
      <Col xs={24} sm={24} md={12}>
        <ProgressBar
          percent={95}
          text="YML"
        />
        <ProgressBar
          percent={90}
          text="CI/CD"
        />
        <ProgressBar
          percent={70}
          text="Infrasctructure as a Code"
        />
        <ProgressBar
          percent={95}
          text="Troubleshooting"
        />
      </Col>
    </Row>
    <br></br>
    <h6>☁️ Yes, this site is 100% Serverless. </h6>
    <br></br>
  </div>
);

export default SkillsProgress;
